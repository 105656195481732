import axios from "axios";
import { logError, logTrace } from "../ApiServices/LoggingService";

const getUserInfo = async (successCallback, errorCallback) => {
  await get("/api/Identity/GetUserInfo", successCallback, errorCallback);
};

const getUserIncentiveSummary = async (successCallback, errorCallback) => {
  await get("/api/Incentive/summary/user", successCallback, errorCallback);
};

const getUserSummary = async (successCallback, errorCallback) => {
  await get("/api/Incentive/GetUserSummary", successCallback, errorCallback);
}

const getAvailableIncentives = async (successCallback, errorCallback) => {
  await get(
    "/api/AgencyIncentives/GetAvailableIncentives", 
    successCallback,
    errorCallback
  );
};

const getPendingIncentives = async (successCallback, errorCallback) => {
  await get(
    "/api/AgencyIncentives/GetPendingIncentives",
    successCallback,
    errorCallback
  );
};

const getUserPaymentHistory = async (token, successCallback, errorCallback) => {
    await getWithHeaders(
      "/api/Incentive/GetUserPaymentHistory",
      {'Token': token},
      successCallback,
      errorCallback
    );
};

const getPayoutToken = async (incentiveId, successCallback, errorCallback) => {
  await get(
    "/api/Incentive/GetPayoutToken?incentiveId=" + incentiveId,
    successCallback,
    errorCallback
  );
};

const getExternalUrls = async (successCallback, errorCallback) => {
  await get(
    "/api/Lookup/GetRewardsExternalUrls", 
    successCallback,
    errorCallback
  );
};

const getEcamsUrl = async (successCallback, errorCallback) => {
  await get("/api/Lookup/GetEcamsUrl", successCallback, errorCallback);
};

const getQMUrl = async (successCallback, errorCallback) => {
  await get("/api/Lookup/GetQMUrl", successCallback, errorCallback);
};

const updateAgentTaxInfoOnFile = async (
  update,
  requestData,
  successCallback,
  errorCallback
) => {
  await post(
    "/api/Identity/UpdateAgentTaxInfoOnFile?update=" + update,
    requestData,
    successCallback,
    errorCallback
  );
};

const saveTaxInfo = async (requestData, successCallback, errorCallback) => {
  await post(
    "/api/TaxForm/SaveTaxInfo",
    requestData,
    successCallback,
    errorCallback
  );
};

const uploadTaxInfo = async (successCallback, errorCallback) => {
  await post(
    "/api/TaxForm/UploadTaxInfo",
    successCallback,
    errorCallback
  );
};

const logout = async (successCallback, errorCallback) => { 
  get("/api/Identity/Logout", successCallback, errorCallback);
};

const Enroll = async (requestData, successCallback, errorCallback) => {
  await post(
    "/api/AgencyIncentives/Enroll",
    requestData,
    successCallback,
    errorCallback
  );
};

export async function updateBankingInfo(
  updateBankingInfoRequest,
  successCallback,
  errorCallback
) {
  await post(
    "/api/Billing/UpdateBankingInfo",
    updateBankingInfoRequest,
    successCallback,
    errorCallback
  );
}

export async function getAccountNumberLastFour(successCallback, errorCallback) {
  await get("/api/Billing/GetAccountNumberLastFour", successCallback, errorCallback);
}

export async function getCancelFlag(successCallback, errorCallback) {
  await get("/api/FeatureFlag/GetCancelFlag", successCallback, errorCallback);
}

export async function getPendingPromotionsFlag(successCallback, errorCallback) {
  await get("/api/FeatureFlag/GetPendingPromotionsFlag", successCallback, errorCallback);
}

export async function getPrizeWheelSoundFlag(successCallback, errorCallback) {
  await get("/api/FeatureFlag/GetPrizeWheelSoundFlag", successCallback, errorCallback);
}

export async function getPrizeWheelOnHomePageFlag(successCallback, errorCallback) {
  await get("/api/FeatureFlag/GetPrizeWheelOnHomePageFlag", successCallback, errorCallback);
}

export async function getAllFeatureFlags(successCallback, errorCallback) {
  await get("/api/FeatureFlag/GetAllFeatureFlags", successCallback, errorCallback);
}

async function getWithHeaders(endpoint, headers, successCallback, errorCallback) {
  try {
    const response = await axios.get(endpoint, {
      headers: headers
    });
    successCallback(response.data);
  } catch (error) {
    let logMessage = {"message": ""};
    if ("message" in error) {
      logMessage = {"message": error.message};
    }
    else {
      logMessage = {"message": "no error message found"};
    }

    logError(logMessage, "getWithHeaders request from " + endpoint);
    errorCallback(error);
  }
}

async function get(endpoint, successCallback, errorCallback) {
  try {
    const response = await axios.get(endpoint);
    successCallback(response.data);
  } catch (error) {
    let logMessage = {"message": ""};
    if ("message" in error) {
      logMessage = {"message": error.message};
    }
    else {
      logMessage = {"message": "no error message found"};
    }

    logError(logMessage, "get request from " + endpoint);
    errorCallback(logMessage);
  }
}

async function post(endpoint, requestData, successCallback, errorCallback) {
  try {
    let response = await axios.post(endpoint, requestData.data, {
      headers: requestData.headers
    });
    successCallback(response.data);
  } catch (error) {
    let logMessage = {"message": ""};
    if ("message" in error) {
      logMessage = {"message": error.message};
    }
    else {
      logMessage = {"message": "no error message found"};
    }

    logError(logMessage, "post request from " + endpoint);
    errorCallback(logMessage);
  }
}

export {
  getUserInfo,
  getUserIncentiveSummary,
  getUserSummary,
  getUserPaymentHistory,
  getAvailableIncentives,
  getPendingIncentives,
  getPayoutToken,
  getExternalUrls,
  getEcamsUrl,
  updateAgentTaxInfoOnFile,
  Enroll,
  saveTaxInfo,
  uploadTaxInfo,
  logout,
  getQMUrl
};
