import React, { useState, useEffect } from "react";
import { UserIncentiveSummaryContext } from "./userContext";
import { useNavigate } from "react-router-dom";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";

const UserIncentiveSummaryContextProvider = ({ children }) => {
    const [userIncentiveSummary, setUserIncentiveSummary] = useState(null);
    const navigate = useNavigate();

    async  function getUserIncentiveSummary() {
      const handleError = (error) => {
        logTrace(error, "error when calling rewardsApi.getUserIncentiveSummary", "getUserIncentiveSummary in UserIncentiveSummaryContextProvider.jsx");
      };

      const handleSuccess = (responseData) => {
        setUserIncentiveSummary(responseData.response.sort(function(a,b){ return Date.parse(a.endDate) - Date.parse(b.endDate)}));
      }
      await rewardsApi.getUserIncentiveSummary(handleSuccess, handleError);
    }

    useEffect(() => {
      getUserIncentiveSummary();
    }, [navigate]);

    return <UserIncentiveSummaryContext.Provider value={userIncentiveSummary}>{children}</UserIncentiveSummaryContext.Provider>;
  };
  
  export default UserIncentiveSummaryContextProvider;
  