import React, { useState, useEffect } from "react";
import { Wheel } from "react-custom-roulette";
import CelebrationModal from "./CelebrationModal.jsx";
import WheelTick from "../sounds/wheel-tick-v2.wav";
import PrizeWheelDisabled from "../images/PrizeWheelDisabled.svg";
import blueGift from "../images/presentBlue.png";
import QuantumMetrics from "./QuantumMetrics";
import "../styles/wheel.css";

const pointer = {
  src: "/images/pointer.png",
  style: {
    transform: "rotate(-11deg) scale(1.15)",
    margin: "15px"
  }
};

// This version of the wheel is ONLY used on the Home page
// Called in Home.jsx when gameOnHomePageFlag is on
const PrizeWheel = ({
  chosenIncentiveId,
  incentiveName,
  daysLeftBeforeEndDate,
  redeemDaysLeft,
  numberOfSpins,
  updateTotalEarnings,
  payoutOptions,
  prizeWheelSoundFlag,
  getPayoutToken,
  updateSpinsForIncentive,
  toggle
}) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prizeWheelDisabled, setPrizeWheelDisabled] = useState(false);
  const [processingData, setProcessingData] = useState(false);
  const [celebrationModalVisible, setCelebrationModalVisible] = useState(false);
  const [muteWheelSound, setMuteWheelSound] = useState(false);
  const [disableMuteButton, setDisableMuteButton] = useState(false);

  const handleSpinClick = async () => {
    const audio = new Audio(WheelTick);

    setProcessingData(true);
    setDisableMuteButton(true);
    if (!mustSpin) {
      const payoutAmount = await getPayoutToken(chosenIncentiveId);
      setPrizeNumber(
        payoutOptions.findIndex((o) => o.option === "$" + payoutAmount)
      );

      const newSpins = numberOfSpins - 1;
      updateSpinsForIncentive(chosenIncentiveId, newSpins);
      setCelebrationModalVisible(false);
      setMustSpin(true);

      if (prizeWheelSoundFlag && !muteWheelSound) {
        audio.play();
      }
    }
  };

  useEffect(() => {
    if (!processingData) {
      setPrizeWheelDisabled(numberOfSpins === 0);
    }
  }, [numberOfSpins, processingData]);

  return (
    <>
      <div>
        <div>
          <h4 className="home-page-game-header">{incentiveName}</h4>
          {prizeWheelSoundFlag && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <>
              {!disableMuteButton ? (
                <a
                  className="home-page-game-header-icon"
                  onClick={() => {
                    setMuteWheelSound(!muteWheelSound);
                  }}
                >
                  {muteWheelSound ? (
                    <span
                      aria-label="Unmute wheel noise"
                      className="geico-icon geico-icon--actionable icon-muted-speaker"
                    ></span>
                  ) : (
                    <span
                      aria-label="Mute wheel noise"
                      className="geico-icon geico-icon--actionable icon-speaker"
                    ></span>
                  )}
                </a>
              ) : (
                <a className="home-page-game-header-icon">
                  {muteWheelSound ? (
                    <span
                      aria-label="Disabled unmute wheel noise"
                      className="geico-icon icon-muted-speaker"
                      style={{ color: "gray" }}
                    ></span>
                  ) : (
                    <span
                      aria-label="Disabled mute wheel noise"
                      className="geico-icon icon-speaker"
                      style={{ color: "gray" }}
                    ></span>
                  )}
                </a>
              )}
            </>
          )}
        </div>
        <div>
          <p className="description-text">
            Use your tokens to spin the wheel and earn prizes. Read more
            information about this promotion{" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                toggle();
              }}
            >
              here
            </a>
            .
          </p>
        </div>
        {daysLeftBeforeEndDate > 0 ? (
          <span className="days-left-counter-bold">
            {daysLeftBeforeEndDate} days left
          </span>
        ) : (
          <span className="redemption-days-left-counter">
            {redeemDaysLeft} days to redeem
          </span>
        )}
      </div>
      <div className="game-segment">
        {!prizeWheelDisabled ? (
          <div className="wheel">
            <div className="wheel-base" data-testid="spinner-wheel-base">
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                spinDuration={0.7}
                data={payoutOptions}
                radiusLineColor="white"
                radiusLineWidth={7}
                outerBorderWidth={2}
                outerBorderColor="white"
                perpendicularText={false}
                fontSize={28}
                textDistance={75}
                textColors={["white"]}
                backgroundColors={["#1D4A96", "#573E94", "#1D4A96", "#9380C7"]}
                pointerProps={pointer}
                onStopSpinning={() => {
                  setMustSpin(false);
                  setTimeout(() => {
                    setCelebrationModalVisible(true);
                    setProcessingData(false);
                    setDisableMuteButton(false);
                    updateTotalEarnings(parseFloat((payoutOptions[prizeNumber].option).replace("$", "")));
                  }, 800);
                }}
              />
            </div>
            <div className="prize-wheel-button">
              <div
                className="inner-circle-small"
                onClick={handleSpinClick}
                disabled={processingData}
              >
                <div
                  className="button-text"
                  data-testid="prize-wheel-button-test"
                >
                  <span className="button-text-top">Push to</span>
                  <span className="button-text-bottom">Spin!</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="wheel" data-testid="prize-wheel-disabled-test">
            <img src={PrizeWheelDisabled} alt="No Tokens Prize Wheel" />
          </div>
        )}
      </div>
      <div className="spin-text-with-image">
        {numberOfSpins > 0 ? (
          <div>
            <img src={blueGift} alt="gift" />
            <h4
              className="spin-text"
              data-testid="prize-wheel-spins-left-home-test"
            >
              You have {numberOfSpins.toLocaleString()} token
              {numberOfSpins > 1 ? "s" : ""} remaining.
            </h4>
          </div>
        ) : (
          <div>
            <h4
              className="spin-text"
              data-testid="prize-wheel-spins-left-home-test"
            >
              You have 0 tokens remaining.
            </h4>
          </div>
        )}
      </div>
      {celebrationModalVisible && (
        <CelebrationModal prizeAmount={payoutOptions[prizeNumber].option} />
      )}
      <QuantumMetrics />
    </>
  );
};

export default PrizeWheel;
