const currencyFormat = (promotionEarnings) => {
  return (
    "$" + promotionEarnings.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

const getDaysRemaining = (enrollmentEndDate) => {
    if (enrollmentEndDate === null) {
        return null;
    }
    const currentDate = new Date();
    const endDate = new Date(enrollmentEndDate);
    const timeDifference = endDate.getTime() - currentDate.getTime();
    const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysRemaining;
}

const convertDate = (date) => {
  const convert = date.split("T");
  const day = convert[0].split('-');
  return day[1] + "/" + day[2] + "/" + day[0];
}

export { currencyFormat, getDaysRemaining, convertDate };
