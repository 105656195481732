import axios from "axios";

const buildInfo = (response, message, action, request = "") => {
    return {
        Response: JSON.stringify(response),
        Message: JSON.stringify(message),
        Action: JSON.stringify(action),
        Request: JSON.stringify(request)
    };
}

const buildExceptionInfo = (exceptionLog, action = "") => {
    return {
        Message: JSON.stringify(exceptionLog),
        Action: JSON.stringify(action)
    };
}

const logError = (exceptionLog, exceptionAction) => {
    var exceptionInfo = buildExceptionInfo(exceptionLog, exceptionAction);
    axios.post('/api/Logging/WriteErrorLog', exceptionInfo, { headers: { "Content-Type": "application/json" } });
};  

const logTrace = (traceResponse, message, action, request = "") => {
    var traceInfo = buildInfo(traceResponse, message, action, request);
    axios.post('/api/Logging/WriteTraceLog', traceInfo, { headers: { "Content-Type": "application/json" } });
};

export { logError, logTrace };