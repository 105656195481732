import React, { useState } from "react";
import { Modal } from "reactstrap";
import "../styles/CelebrationModal.css";
import arrow from "../images/e361-arrow-left.svg";
import celebrateCircle from "../images/Celebrate.svg";
import confetti from "../images/Confetti.svg";

const CelebrationModal = (props) => {
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        fade={false}
        contentClassName="modal-style"
      >
        <div className="text-body">
          <div className="celebrate-image">
            <img
              src={celebrateCircle}
              alt="celebrate"
              className="stacked-image-circle"
            ></img>
            <img
              src={confetti}
              alt="confetti"
              className="stacked-image-confetti"
            ></img>
          </div>
          <div className="celebrate-text">
            <h2>Congrats!</h2>
            {(props?.prizeAmount != null || props?.prizeAmount !== 0) && (
              <p>You won {props?.prizeAmount} from spinning the wheel!</p>
            )}
            <button className="btn--primary continue-button" onClick={toggle}>
              <img src={arrow} alt="continue"></img>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CelebrationModal;
