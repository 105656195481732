const PendingModalContent = ({ daysRemaining, promotionDescription }) => {
  return (
    <>
      <h4>{daysRemaining} days until start</h4>

      <div>
        <p>{promotionDescription}</p>
      </div>
    </>
  );
};

export default PendingModalContent;
