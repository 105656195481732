import { useEffect, useState } from "react";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";
import QuantumMetrics from "./QuantumMetrics";

const AccessDenied = () => {
  const [gatewayUrl, setGatewayUrl] = useState(null);

  const getFederatedLoginUrl = () => {
    const handleError = (error) => {
      logTrace(error, "error when calling rewardsApi.getExternalUrls", "getFederatedLoginUrl in AccessDenied.jsx");
    };
    const handleSuccess = (response) => {
      setGatewayUrl(response.gatewayUrl);
    };

    rewardsApi.getExternalUrls(handleSuccess, handleError);
  };

  useEffect(() => {
    getFederatedLoginUrl();
  }, []);

  return (
    <>
      <div className="container">
        <form>
          <div className="row">
            <div className="hidden-sm col-md-4">
              <img src="/images/geckoPose.png" alt="Gecko" />
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="encounter-error-message">
                <div className="section-header">
                  <div className="section-header-title-subtext">
                    <h3 className="font--bold">Access Not Allowed</h3>
                    <br />
                    <p>
                      <span>
                        You are not authorized to access this part of the site.
                        Talk to your principal to get access.
                      </span>
                    </p>
                    <br />
                    <p>
                      <a
                        className="btn btn--primary"
                        href={gatewayUrl}
                        rel="noreferrer"
                      >
                        Return to GEICO Gateway
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <QuantumMetrics />
    </>
  );
};
export default AccessDenied;
