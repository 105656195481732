/* eslint-disable jsx-a11y/anchor-is-valid */
import { GdkNavigation } from "@gdk/navigation";
import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { FeatureFlagContext } from "../context/FeatureFlagContextProvider";
import * as RewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";

const PrimaryNavigation = () => {
  var gdkNavigation = useRef();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const featureFlags = useContext(FeatureFlagContext);
  const [gatewayUrl, setGatewayUrl] = useState(null);
  const [contactUsUrl, setContactUsUrl] = useState(null);
  const [logoutUrl, setLogoutUrl] = useState(null);
  const [onboarded, setOnboarded] = useState();
  const [gameOnHomePageFlag, setGameOnHomePageFlag] = useState(false);

  useEffect(() => {
    handleFeatureFlags();
  }, [featureFlags]);

  const handleFeatureFlags = () => {
    setGameOnHomePageFlag(featureFlags?.PrizeWheelOnHomePageFlag);
  };

  useEffect(() => {
    gdkNavigation.current = new GdkNavigation({
      content: "#primary-navigation"
    });
    getFederatedLoginUrl();
  }, []);

  const getFederatedLoginUrl = () => {
    const handleError = (error) => {
      logTrace(
        error,
        "error when calling RewardsApi.getExternalUrls",
        "getFederatedLoginUrl in PrimaryNavigation.jsx"
      );
    };
    const handleSuccess = (response) => {
      setGatewayUrl(response.gatewayUrl);
      setContactUsUrl(response.contactUsUrl);
      setLogoutUrl(response.logoutUrl);
    };

    RewardsApi.getExternalUrls(handleSuccess, handleError);
  };

  useEffect(() => {
    checkUserOnboarded();
  }, [user]);
  const checkUserOnboarded = () => {
    if (user) {
      setOnboarded(
        user.enrollmentStatus.participationIndicator &&
          user.enrollmentStatus.taxInfo &&
          user.enrollmentStatus.bankingOnFile
      );
    } else {
      setOnboarded(false);
    }
  };

  const routeToOnboarding = () => {
    navigate("/onboarding", { state: { editMode: true } });
    gdkNavigation.current.closeNavigation();
  };

  async function logout() {
    const handleError = (error) => {
      logTrace(error, "error when calling RewardsApi.logout", "logout in PrimaryNavigation.jsx");
    };
    const handleSuccess = (response) => {};

    await RewardsApi.logout(handleSuccess, handleError);
  }

  return (
    <nav id="primary-navigation" role="navigation">
      <div className="nav-background"></div>
      <div className="panel-wrapper" data-side-panel="menu" data-testid="main-menu">
        {/* Hamburger Menu */}
        <div className="nav-menu">
          <ul className="nav-primary-tier nav-items nav-dark-mode-switch-wrapper">
            {onboarded && (
              <li className="menu">
                <a className="nav-menu-item" onClick={routeToOnboarding}>
                  W-9 and Bank Info
                </a>
              </li>
            )}
            {!gameOnHomePageFlag && (
              <li className="menu">
                <a className="nav-menu-item" href={gatewayUrl} target="_blank" rel="noreferrer">
                  GEICO Gateway
                </a>
              </li>
            )}
            <li className="nav-additional-links menu">
              <div className="nav-bottom-links">
                <ul className="list">
                  <li className="menu">
                    <a href={contactUsUrl} target="_blank" rel="noreferrer">
                      Contact Us
                    </a>
                  </li>
                  <li className="menu">
                    <span className="geico-icon--small icon-logout icon-padding"></span>
                    <a href={logoutUrl} onClick={logout}>
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PrimaryNavigation;
