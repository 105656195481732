import "../styles/BankingDetails.css";
import "../styles/taxForm.css";
import "../styles/gdk";
import { GdkLoader } from "@gdk/loader";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ToolTip from "../components/ToolTip";
import ExemptInput from "./TaxFormInputs/ExemptInput";
import FatcaCodeTooltipContent from "./TaxFormInputs/FatcaCodeTooltipContent";
import StateInputOptions from "./TaxFormInputs/StateInputOptions";
import SsnInput from "./TaxFormInputs/SsnInput";
import FeinInput from "./TaxFormInputs/FeinInput";
import ClassificationInput from "./TaxFormInputs/ClassificationInput";
import { taxFormValidate } from "../helpers/validationHelper";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UniversalModal from "./UniversalModal";
import { CONFIRMATION } from "../utils/constants/index.js";
import QuantumMetrics from "./QuantumMetrics";

const TaxForm = () => {
  // Validation Schema
  let validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Please enter the name of the entity/individual"),
    businessName: yup.string().optional(),
    classifications: yup
      .string()
      .required("Please select your federal tax classification"),
    llcType: yup.string().when("classifications", {
      is: (value) => value === "Limited Liability Company (LLC)",
      then: (schema) => schema.required("Please choose an LLC type"),
    }),
    otherTaxClass: yup.string().when("classifications", {
      is: (value) => value === "Other",
      then: (schema) => schema.required("Please enter your tax classification"),
    }),
    exemptCode: yup.string().optional(),
    fatcaCode: yup.string().optional(),
    address: yup
      .string()
      .max(40, "Address is too long")
      .required("Please enter your address"),
    city: yup
      .string()
      .max(40, "City is too long")
      .required("Please enter your city"),
    states: yup.string().max(2).required("Please select your state"),
    zip: yup
      .string()
      .matches(/^[\d]{0,5}$/, "Invalid Zip.")
      .required("Please enter your zip"),
    account: yup.string().optional().max(50, "Account number is too long"),
  });

  // Form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      businessName: "",
      classifications: "",
      llcType: "",
      otherTaxClass: "",
      exemptCode: "",
      fatcaCode: "",
      address: "",
      city: "",
      states: "",
      zip: "",
      account: "",
      ssn: "",
      fein: "",
    },
  });

  // State variables
  const gdkLoader = useRef(null);
  const [ssnLock, setSsnLock] = useState(false);
  const [feinLock, setFeinLock] = useState(false);
  const [showError, setShowError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelFlag, setCancelFlag] = useState(true);
  const location = useLocation();
  const [llcTypeRequired, setLlcTypeRequired] = useState(false);
  const [otherTaxClassRequired, setOtherTaxClassRequired] = useState(false);

  const handleClassificationChange = (text) => {
    setLlcTypeRequired(text.target.value === "Limited Liability Company (LLC)");
    setOtherTaxClassRequired(text.target.value === "Other");
  };

  const onSubmit = async (data) => {
    // TODO: Change SSN/FEIN to be non-GDK components so we can validate them like everything else
    const validSsnFein = taxFormValidate();
    if (!validSsnFein) {
      return;
    }

    let ssnPlaceholder = "___-__-____";
    let ssnValue = "";
    let feinValue = "";
    if (document.getElementById("ssn-input-value").value === ssnPlaceholder) {
      feinValue = document
        .getElementById("fein-value")
        .value.replaceAll("-", "");
    } else {
      ssnValue = document
        .getElementById("ssn-input-value")
        .value.replaceAll("-", "");
    }

    const requestData = {
      Name: data.name,
      BusinessName: data.businessName,
      TaxClassification: data.classifications,
      LLCType: data.llcType,
      OtherText: data.otherTaxClass,
      ExemptCode: data.exemptCode,
      ReportCode: data.fatcaCode,
      Address: data.address,
      City: data.city,
      State: data.states,
      ZipCode: data.zip,
      AccountNumber: data.account,
      SSN: ssnValue,
      Fein: feinValue,
    };

    gdkLoader.current.show();

    await rewardsApi.saveTaxInfo(
      {
        data: requestData,
      },
      handleTaxFormSuccess,
      handleTaxFormError
    );
  };

  const toggle = () => setModalVisible(!modalVisible);

  const handleTaxFormSuccess = async (responseData) => {
    window.location.href = responseData;
  };

  const handleTaxFormError = (error) => {
    logTrace(
      error,
      "error when calling rewardsApi.saveTaxInfo",
      "handleTaxFormError in TaxForm.jsx"
    );
    gdkLoader.current.hide();
    setShowError(true);
  };

  const handleCancel = () => {
    toggle();
  };

  const handleFlag = () => {
    const successCallback = (response) => {
      setCancelFlag(response);
    };

    const errorCallback = (error) => {
      logTrace(
        error,
        "error when calling rewardsApi.getCancelFlag",
        "handling flag in TaxForm.jsx"
      );
      setCancelFlag(false);
    };
    rewardsApi.getCancelFlag(successCallback, errorCallback);
  };
  const instructionText =
    "To receive your reward payouts, please fill out the form below. " +
    "Once completed, a W-9 form will be created with your information for review and approval. " +
    "You are able to submit an updated tax form at any time in the menu options.";

  const privacyText =
    "For the security of your personal information, previously entered details are not carried over to GEICO Rewards.";

  useEffect(() => {
    handleFlag();

    gdkLoader.current = new GdkLoader({
      content: "#full-loader",
      type: "section",
    });

    document.getElementById("fein-field").onkeyup = () => {
      if (document.getElementById("fein-field").value === "") {
        setFeinLock(false);
        setSsnLock(false);
      } else {
        setSsnLock(true);
      }
    };
    document.getElementById("ssn-input-field").onkeyup = () => {
      if (document.getElementById("ssn-input-field").value === "") {
        setFeinLock(false);
        setSsnLock(false);
      } else {
        setFeinLock(true);
      }
    };
  }, []);

  return (
    <>
      <div>
        {cancelFlag && modalVisible && (
          <UniversalModal
            setShow={setModalVisible}
            header="Are you sure you want to cancel?"
            contentType={CONFIRMATION}
          />
        )}
        <div className="container">
          <div
            id="full-loader"
            className="loader-container"
            data-testid="loader"
          >
            <div className="loader-content">
              <div className="loader loader--animated" aria-label="Please wait">
                <div className="animated-loader-copy">
                  We are processing your form. Thank you for your patience.
                </div>
              </div>
            </div>
          </div>
          <div id="pageHeader" className="page-header--wrapper">
            <div className="container bottom">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-header pheader">
                    <div className="page-header-left">
                      <h2>GEICO Rewards Tax Form (W-9)</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>{instructionText}</p>
            <div className="privacyText">{privacyText}</div>
          </div>

          {/* Header above, body below */}

          <div id="taxFormContainer" className="tax-form-container">
            <form id="tax-form-validate" onSubmit={handleSubmit(onSubmit)}>
              {showError && (
                <div id="error-message" className="alert">
                  <div className="alert--high-importance">
                    <span className="icon-alert" />
                    <ul className="alert-list">
                      <li className="alert-content">
                        <div className="h4">Tax Form Submission Failure</div>
                        <p>Error submitting tax form. Please try again.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              {/* Below are the form fields */}

              {/* Name/Entity input */}
              <div
                id="name-form"
                className={`form-field large-text-input ${
                  errors.name && `form-field--error`
                }`}
              >
                <div className="tooltip-label-container">
                  <label htmlFor="name">Name of Entity/Individual</label>
                  <ToolTip
                    idName="tooltip-name"
                    triggerId="tooltip-trigger-name"
                    body="For a sole proprietor or disregarded entity, enter the owner’s name in this field, and enter the business/disregarded entity’s name below."
                  ></ToolTip>
                </div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  data-testid="nameTest"
                  autoComplete="name"
                  size="50"
                  {...register("name")}
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toUpperCase())
                  }
                />
                {errors.name && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.name.message}
                    </span>
                  </>
                )}
              </div>

              {/* Business name input */}
              <div
                id="businessName-form"
                className={`form-field large-text-input ${
                  errors.businessName && `form-field--error`
                }`}
              >
                <div>
                  <label htmlFor="businessName">
                    Business Name or Disregarded Entity Name
                  </label>
                  <ToolTip
                    idName="tooltip-businessname"
                    triggerId="tooltip-trigger-businessname"
                    body="If you have a business name, trade name, DBA name, or disregarded entity name, enter it here."
                  ></ToolTip>
                </div>
                <span
                  id="businessNameDesciption"
                  className="form-descriptive-copy"
                >
                  Enter if different than Name of Entity/Individual.
                </span>
                <input
                  id="businessName"
                  name="businessName"
                  data-testid="businessNameTest"
                  type="text"
                  size="50"
                  {...register("businessName")}
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toUpperCase())
                  }
                />
                {errors.businessName && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.businessName.message}
                    </span>
                  </>
                )}
              </div>

              {/* Classifications input */}
              <div
                id="classifications-form"
                className={`form-field large-text-input ${
                  errors.classifications && `form-field--error`
                }`}
              >
                <div>
                  <label htmlFor="classifications">
                    Federal Tax Classification
                  </label>
                  <ToolTip
                    idName="tooltip-classification"
                    triggerId="tooltip-trigger-classification"
                    body={<ClassificationInput />}
                  ></ToolTip>
                </div>
                <div className="select-box">
                  <select
                    id="classifications"
                    name="classifications"
                    data-testid="classificationsTest"
                    {...register("classifications")}
                    onChange={(v) => {
                      handleClassificationChange(v);
                    }}
                  >
                    <option value="">Please Select</option>
                    <option value="Individual/Sole Proprietor">
                      Individual/Sole Proprietor
                    </option>
                    <option value="C Corporation">C Corporation</option>
                    <option value="S Corporation">S Corporation</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Trust/Estate">Trust/Estate</option>
                    <option value="Limited Liability Company (LLC)">
                      Limited Liability Company (LLC)
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                {errors.classifications && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.classifications.message}
                    </span>
                  </>
                )}
              </div>

              {/* Optional LLC Type input if LLC is selected */}
              {llcTypeRequired === true && (
                <div
                  id="llcType-form"
                  className={`form-field large-text-input ${
                    errors.llcType && `form-field--error`
                  }`}
                >
                  <div className="tooltip-label-container">
                    <label htmlFor="llcType">LLC Type</label>
                    <ToolTip
                      idName="tooltip-llcType"
                      triggerId="tooltip-trigger-llcType"
                      body="Select the appropriate tax classification of the LLC, unless it is a disregarded entity. 
                    A disregarded entity should instead choose the appropriate tax classification of its owner"
                    ></ToolTip>
                  </div>
                  <div className="select-box">
                    <select
                      id="llcType"
                      name="llcType"
                      data-testid="llcTypeTest"
                      {...register("llcType")}
                    >
                      <option value="">Please Select</option>
                      <option value="C">C Corporation</option>
                      <option value="S">S Corporation</option>
                      <option value="P">Partnership</option>
                    </select>
                  </div>
                  {errors.llcType && (
                    <>
                      <span className="form-message" role="alert">
                        {errors.llcType.message}
                      </span>
                    </>
                  )}
                </div>
              )}

              {/* Optional Other Tax Classification input if Other is selected */}
              {otherTaxClassRequired === true && (
                <div
                  id="otherTaxClass-form"
                  className={`form-field large-text-input ${
                    errors.otherTaxClass && `form-field--error`
                  }`}
                >
                  <div className="tooltip-label-container">
                    <label htmlFor="otherTaxClass">
                      Other Tax Classification
                    </label>
                    <ToolTip
                      idName="tooltip-otherTaxClass"
                      triggerId="tooltip-trigger-otherTaxClass"
                      body="This field should be used for classifications that are not listed such as nonprofits, governmental entities, etc."
                    ></ToolTip>
                  </div>
                  <input
                    id="otherTaxClass"
                    name="otherTaxClass"
                    type="text"
                    style={{ width: "60%" }}
                    {...register("otherTaxClass")}
                  />
                  {errors.otherTaxClass && (
                    <>
                      <span className="form-message" role="alert">
                        {errors.otherTaxClass.message}
                      </span>
                    </>
                  )}
                </div>
              )}

              {/* Exempt code can be numbers between 1 and 13 */}
              <div id="exempt">
                <div
                  id="exemptCode-form"
                  className={`form-field large-text-input ${
                    errors.exemptCode && `form-field--error`
                  }`}
                >
                  <label htmlFor="exemptCode">Exempt Payee Code</label>
                  <ToolTip
                    idName="tooltip-exemptCode"
                    triggerId="tooltip-trigger-exemptCode"
                    body={<ExemptInput />}
                  ></ToolTip>
                  <span className="form-descriptive-copy " id="exemptCodedesc">
                    (Optional)
                  </span>
                  <div className="select-box">
                    <select
                      id="exemptCode"
                      name="exemptCode"
                      data-testid="exemptCodeTest"
                      {...register("exemptCode")}
                    >
                      <option value="">Please Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                    </select>
                  </div>
                  {errors.exemptCode && (
                    <>
                      <span className="form-message" role="alert">
                        {errors.exemptCode.message}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* code can be letters between A and M */}
              <div id="fatcacode-container">
                <div
                  id="fatcacode-form"
                  className={`form-field large-text-input ${
                    errors.fatcaCode && `form-field--error`
                  }`}
                >
                  <label htmlFor="fatcaCode">
                    Exemption From FATCA Reporting Code
                  </label>
                  <ToolTip
                    idName="tooltip-fatcacode"
                    triggerId="tooltip-trigger-fatcacode"
                    body={<FatcaCodeTooltipContent />}
                  ></ToolTip>
                  <span className="form-descriptive-copy " id="fatcacodedesc">
                    (Optional)
                  </span>
                  <div className="select-box">
                    <select
                      id="fatcaCode"
                      name="fatcaCode"
                      data-testid="fatcaCodetest"
                      {...register("fatcaCode")}
                    >
                      <option value="">Please Select</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                      <option value="F">F</option>
                      <option value="G">G</option>
                      <option value="H">H</option>
                      <option value="I">I</option>
                      <option value="J">J</option>
                      <option value="K">K</option>
                      <option value="L">L</option>
                      <option value="M">M</option>
                    </select>
                  </div>
                  {errors.fatcaCode && (
                    <>
                      <span className="form-message" role="alert">
                        {errors.fatcaCode.message}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Address Street 1 input */}
              <div
                id="address-form"
                className={`form-field large-text-input ${
                  errors.address && `form-field--error`
                }`}
              >
                <div className="tooltip-label-container">
                  <label htmlFor="address">Address</label>
                  <ToolTip
                    idName="tooltip-address"
                    triggerId="tooltip-trigger-address"
                    body="Enter your address (number, street, and apartment or suite number). This is where GEICO, 
                        as the requester of this Form W-9, will mail your information returns. If a new address is provided, 
                        there is still a chance the old address will be used until GEICO changes your address in our records."
                  ></ToolTip>
                </div>
                <span id="addressdesc" className="form-descriptive-copy">
                  Number, Street, and Apartment or Suite Number
                </span>
                <input
                  id="address"
                  name="address"
                  type="text"
                  data-testid="addressTest"
                  autoComplete="street-address"
                  size="50"
                  {...register("address")}
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toUpperCase())
                  }
                />
                {errors.address && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.address.message}
                    </span>
                  </>
                )}
              </div>

              {/* City input */}
              <div
                id="city-form"
                className={`form-field large-text-input ${
                  errors.city && `form-field--error`
                }`}
              >
                <div>
                  <label htmlFor="city">City</label>
                </div>
                <input
                  id="city"
                  name="city"
                  type="text"
                  data-testid="cityTest"
                  size="50"
                  {...register("city")}
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toUpperCase())
                  }
                />
                {errors.city && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.city.message}
                    </span>
                  </>
                )}
              </div>

              {/* State input */}
              <div
                id="states-form"
                className={`form-field large-text-input ${
                  errors.states && `form-field--error`
                }`}
              >
                <div>
                  <label htmlFor="states">State</label>
                </div>
                <div className="select-box">
                  <select
                    id="states"
                    name="states"
                    data-testid="statesTest"
                    {...register("states")}
                    placeholder="Select a State"
                  >
                    <StateInputOptions />
                  </select>
                </div>
                {errors.states && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.states.message}
                    </span>
                  </>
                )}
              </div>

              {/* Zip code input */}
              <div
                id="zip-form"
                className={`form-field large-text-input ${
                  errors.zip && `form-field--error`
                }`}
              >
                <div>
                  <label htmlFor="zip">Zip Code</label>
                </div>
                <input
                  id="zip"
                  name="zip"
                  type="text"
                  data-testid="zipTest"
                  size="20"
                  {...register("zip")}
                />
                {errors.zip && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.zip.message}
                    </span>
                  </>
                )}
              </div>

              {/* Account number input */}
              <div
                id="account-form"
                className={`form-field large-text-input ${
                  errors.account && `form-field--error`
                }`}
              >
                <label htmlFor="account">Account Numbers</label>
                <span className="form-descriptive-copy " id="accountdesc">
                  (Optional)
                </span>
                <input
                  id="account"
                  name="account"
                  type="text"
                  data-testid="accountTest"
                  size="50"
                  {...register("account")}
                />
                {errors.account && (
                  <>
                    <span className="form-message" role="alert">
                      {errors.account.message}
                    </span>
                  </>
                )}
              </div>

              {/* SSN input */}
              <SsnInput lock={ssnLock} />
              {errors.ssn && (
                <>
                  <span className="form-message" role="alert">
                    {errors.ssn.message}
                  </span>
                </>
              )}

              <h4>OR</h4>
              <br />

              {/* FEIN input */}
              <FeinInput lock={feinLock} />
              {errors.fein && (
                <>
                  <span className="form-message" role="alert">
                    {errors.fein.message}
                  </span>
                </>
              )}

              <div>
                <button
                  type="submit"
                  data-testid="submit"
                  className="btn btn--primary btn--pull-right button-Banking"
                >
                  Create and Review W-9
                </button>
                {cancelFlag && location?.state?.edit && (
                  <button
                    type="button"
                    data-testid="cancel-button"
                    className="btn btn--secondary button-Banking btn--pull-right"
                    onClick={handleCancel}
                  >
                    <span>Cancel</span>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <QuantumMetrics />
    </>
  );
};

export default TaxForm;
