import "../styles/Onboarding.css";
import "../styles/gdk";
import { useState, useEffect, useRef, useContext } from "react";
import { GdkLoader } from "@gdk/loader";
import { GdkCheckpoint } from "@gdk/checkpoint";
import { GdkToasts } from "@gdk/toasts";
import BankingDetails from "./BankingDetails";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { getAccountNumberLastFour } from "../services/ApiServices/ApiService";
import {
  BANKING_ACCOUNT_NEEDS_UPDATING,
  DOCUSIGN_DECLINE,
  DOCUSIGN_EVENT,
  DOCUSIGN_SIGNING_COMPLETE,
} from "../utils/constants/index";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";
import QuantumMetrics from "./QuantumMetrics";

const Onboarding = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  const [taxFormOnFile, setTaxFormOnFile] = useState(false);
  const [bankingOnFile, setBankingOnFile] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editTaxMode, setEditTaxMode] = useState(false);
  const [editBankingMode, setEditBankingMode] = useState(false);

  const [taxButtonText, setTaxButtonText] = useState("Complete My W-9");
  const [toastHeader, setToastHeader] = useState("");
  const [toastDescription, setToastDescription] = useState("");

  const [showBillingApiErrorAlert, setShowBillingApiErrorAlert] =
    useState(false);

  const gdkLoader = useRef(null);
  const gdkFullPageLoader = useRef(null);
  const gdkCheckpoint = useRef(null);
  const gdkToast = useRef(null);

  const navigate = useNavigate();

  const routeToTaxForm = () => {
    if (editTaxMode) {
      navigate("/taxForm", { state: { edit: true } });
    } else {
      navigate("/taxForm");
    }
  };

    const editTax = () => {
        setEditMode(false);
        setEditTaxMode(true);
        setTaxFormOnFile(false);
        setTaxButtonText("Submit New W-9");
    };

  const editBanking = () => {
    setEditMode(false);
    setEditBankingMode(true);
    setBankingOnFile(false);
  };

  const updateAgentTaxInfoOnFile = async (change) => {
    let apiCallSuccess = false;
    let postResult;

    const handleError = (error) => {
      logTrace(error, "error when calling rewardsApi.updateAgentTaxInfoOnFile" , "Updating agent tax info");
    };

    const handleSuccess = (responseData) => {
      apiCallSuccess = true;
      postResult = responseData;
    };

    await rewardsApi.updateAgentTaxInfoOnFile(
      change,
      { data: change },
      handleSuccess,
      handleError
    );

    if (apiCallSuccess) {
      return postResult;
    }
  };

  const uploadTaxForm = async () => {

    const handleError = (error) => {
      logTrace(error, "error when calling rewardsApi.uploadTaxInfo", "Uploading tax form");
    };

    const handleSuccess = (responseData) => {};

    await rewardsApi.uploadTaxInfo(handleSuccess, handleError);
  };

    const initializeGdkCheckpoint = (
        taxFormOnFileFromLanding,
        editModeFromNavMenu,
        bankingCheckpointStatus
    ) => {
        const success = (data) => {
            if (typeof data === 'number' || (typeof data === 'string' && data.length === 4)) {
                init(`Checking account ending in ${data}`);
            }
            else {
                init("We don't have your banking details on record yet.");
            }
        };

    const error = () => {
      init("We don't have your banking details on record yet.");
    };

        const init = (bankInfoDescription) => {
            gdkCheckpoint.current = new GdkCheckpoint({
                content: "#two-steps",
                data: [
                    {
                        title: "W-9 submitted electronically",
                        status: taxFormOnFileFromLanding ? "completed" : "in process",
                        description: editModeFromNavMenu
                            ? "Your tax information is on file."
                            : "This is for tax purposes.",
                    },
                    {
                        title: "Current Payout Method On File",
                        status: bankingCheckpointStatus,
                        description: bankInfoDescription,
                    },
                ],
                animated: false,
            });
        }

    getAccountNumberLastFour(success, error);
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const eventType = query.get(DOCUSIGN_EVENT);

    if (!gdkLoader.current) {
      gdkLoader.current = new GdkLoader({
        content: "#extendedWaiting",
        type: "inline",
        autoShow: false,
      });
    }

    if (!gdkFullPageLoader.current) {
      gdkFullPageLoader.current = new GdkLoader({
        content: "#full-loader",
        type: "section",
        autoShow: false,
      });
    }

    // If user directly navigates to /onboarding from Home, enrollment status must be true to access Home, so default to true
    // Otherwise, retrieve state passed from Landing page
    let editModeFromNavMenu = location.state?.editMode ?? false;

    if (user) {
      let taxFormOnFileFromLanding =
        editModeFromNavMenu ||
        user.enrollmentStatus.taxInfo ||
        location.state?.taxFormOnFile;

      let bankingOnFileFromLanding =
        editModeFromNavMenu ||
        user.enrollmentStatus.bankingOnFile ||
        location.state?.bankingOnFile;

      setTaxFormOnFile(taxFormOnFileFromLanding);
      setBankingOnFile(bankingOnFileFromLanding);

      if (editModeFromNavMenu) {
        setEditMode(editModeFromNavMenu);
        setEditTaxMode(false);
        setEditBankingMode(false);
      }

      if (
        !editModeFromNavMenu &&
        taxFormOnFileFromLanding &&
        bankingOnFileFromLanding
      ) {
        gdkLoader.current.show();
      } else {
        gdkLoader.current.hide();
      }

      let bankingCheckpointStatus;

      if (taxFormOnFileFromLanding && !bankingOnFileFromLanding) {
        bankingCheckpointStatus = "in process";
      } else if (!taxFormOnFileFromLanding && !bankingOnFileFromLanding) {
        bankingCheckpointStatus = "";
      } else if (taxFormOnFileFromLanding && bankingOnFileFromLanding) {
        bankingCheckpointStatus = "completed";
      }

      initializeGdkCheckpoint(
        taxFormOnFileFromLanding,
        editModeFromNavMenu,
        bankingCheckpointStatus
      );

      if (
        !editModeFromNavMenu &&
        taxFormOnFileFromLanding &&
        bankingOnFileFromLanding
      ) {
        setTimeout(() => {
          navigate("/home");
        }, 2500);
      }
    }

    gdkToast.current = new GdkToasts({
      content: "#confirmation-toast",
      type: "update",
      autoShow: false,
      floatTime: 3000,
      closeBtn: true,
      baseTimer: true,
    });

    if (location.state != null) {
      if (location.state.displayBankingToast) {
        setToastHeader("Banking Info Updated");
        setToastDescription(
          "Your banking information has been updated successfully."
        );
        gdkToast.current.show();
      } else if (location.state.displayTaxToast) {
        setToastHeader("Tax Form Updated");
        setToastDescription(
          "Your tax information has been updated successfully."
        );
        gdkToast.current.show();
      }
    }

    if (!eventType) {
      return;
    }

    if (eventType === DOCUSIGN_SIGNING_COMPLETE) {
      if (user && !user.enrollmentStatus.taxInfo) {
        gdkFullPageLoader.current.show();
        updateAgentTaxInfoOnFile(true);
      }
      uploadTaxForm();
      setTimeout(() => {
        window.location.reload();
        gdkFullPageLoader.current.hide();
      }, 2000);
    } else if (eventType === DOCUSIGN_DECLINE) {
      setToastHeader("Tax Form Declined");
      setToastDescription(
        "Your tax form was declined. Your information will not be updated or stored by GEICO."
      );
      gdkToast.current.show();
    } else {
      navigate("/Error");
    }

    window.history.replaceState(
      {},
      document.title,
      window.location.origin + window.location.pathname
    );
  }, [location.state, user]);

  return (
    <>
      <div className="container">
        <div id="confirmation-toast" className="toasts-container">
          <div className="toasts toasts--confirmation">
            <div>
              <span className="icon-confirmation"></span>
            </div>
            <div role="status">
              <div className="toasts--heading">{toastHeader}</div>
              <div className="toasts--content">{toastDescription}</div>
            </div>
            <button className="toasts--close-btn icon-close">
              <div id="toasts--base-timer"></div>
            </button>
          </div>
        </div>
        {showBillingApiErrorAlert && (
          <div id="banking-alert" className="alert notice-bar">
            <div className="alert--high-importance">
              <span className="icon-alert"></span>
              <ul className="alert-list">
                <li className="alert-content">
                  <p>
                    We are unable to add your Rewards banking information. To
                    resolve, please confirm your entered banking information is
                    correct. If you continue to experience issues, contact our
                    help desk at 1-800-624-2513 for further assistance.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
        {user?.enrollmentStatus?.bankingStatus ===
          BANKING_ACCOUNT_NEEDS_UPDATING && (
          <div id="banking-alert" className="alert notice-bar">
            <div className="alert--high-importance">
              <span className="icon-alert"></span>
              <ul className="alert-list">
                <li className="alert-content">
                  <p>
                    We are unable to process your payout to the bank account we
                    have on file for you. To resolve, please update your banking
                    details to continue using the Rewards platform.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
        {(editMode || editBankingMode || editTaxMode) && (
          <h2 className="centered-text" data-testid="welcome-heading">
            Manage W-9 and Banking Details
          </h2>
        )}
        {!editMode && taxFormOnFile && bankingOnFile && (
          <h2 className="centered-text" data-testid="welcome-heading">
            You're all set!
          </h2>
        )}
        {!editMode &&
          !editTaxMode &&
          !editBankingMode &&
          (!taxFormOnFile || !bankingOnFile) && (
            <div data-testid="welcome-heading">
              <h2 className="centered-text">Welcome to GEICO Rewards!</h2>
              <p className="centered-text">
                We need a few more items to get you into your dashboard.
              </p>
            </div>
          )}
        <br></br>
        <div className="steps">
          <div
            id="full-loader"
            className="loader-container"
            data-testid="loader"
          >
            <div className="loader-content">
              <div className="loader loader--animated" aria-label="Please wait">
                <div className="animated-loader-copy">Loading...</div>
              </div>
            </div>
          </div>
          <div id="remaining-steps" data-testid="two-steps">
            {!editMode && <h4>2 Quick Steps:</h4>}
            <div id="two-steps" className="checkpoint no-bullet"></div>
          </div>
          <div
            id="next-step"
            className={
              "next-step " +
              (taxFormOnFile && !bankingOnFile ? "wider-flex" : "")
            }
            data-testid="next-step"
          >
            {!taxFormOnFile && (
              <div id="taxForm">
                <h3>Have one of these ready:</h3>
                <p className="closer-spacing">
                  Employer Identification Number (EIN) <b>or</b>
                </p>
                <hr className="smaller-margin"></hr>
                <p>Social Security Number (SSN)</p>
                <button
                  onClick={routeToTaxForm}
                  className="btn btn--primary btn--full-mobile"
                >
                  {taxButtonText}
                </button>
              </div>
            )}
            {taxFormOnFile && !bankingOnFile && (
              <div id="banking">
                {editBankingMode ? (
                  <BankingDetails
                    showBillingHeader={false}
                    edit={true}
                    setShowBillingApiErrorAlert={setShowBillingApiErrorAlert}
                  />
                ) : (
                  <BankingDetails
                    showBillingHeader={false}
                    edit={false}
                    taxFormOnFile={taxFormOnFile}
                    setShowBillingApiErrorAlert={setShowBillingApiErrorAlert}
                  />
                )}
              </div>
            )}
            <div className="center-align">
              <div
                id="extendedWaiting"
                className="loader"
                aria-label="Please wait, it is loading"
              ></div>
              {!editMode && taxFormOnFile && bankingOnFile && (
                <p>Please wait...</p>
              )}
            </div>
            {editMode && (
              <div className="center-align">
                <button
                  type="button"
                  onClick={editTax}
                  className="btn btn--primary top-button-spacing button-min-width"
                >
                  Submit New W-9
                </button>
                <button
                  type="button"
                  onClick={editBanking}
                  className="btn btn--primary button-min-width"
                >
                  Submit New Bank Info
                </button>
              </div>
            )}
          </div>
        </div>
        {editMode && (
          <div className="center-align">
          <button
            type="button"
            onClick={() => {
              navigate("/home");
            }}
            className="btn btn--secondary"
          >
            Return Home
          </button>
          </div>
        )}
      </div>
      <QuantumMetrics />
    </>
  );
};

export default Onboarding;
