import "../styles/BankingDetails.css";
import GdkValidateForm from "@gdk/validate-form";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  removeError,
  RoutingAccountValidate,
} from "../helpers/validationHelper";
import checkNumber from "../images/CheckImage.png";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";
import ToolTip from "./ToolTip";
import ConfirmationModal from "./ConfirmationModal";
import QuantumMetrics from "./QuantumMetrics";

const BankingDetails = ({
  showBillingHeader = true,
  edit = false,
  taxFormOnFile = false,
  setShowBillingApiErrorAlert: setShowBillingApiErrorAlert = () => {},
}) => {
  const [RoutingNumber, setRoutingNumber] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [VerifyAccountNumber, setVerifyAccountNumber] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelFlag, setCancelFlag] = useState(true);

  const handleRoutingNumberChange = (text) => {
    if (!isNaN(text.target.value) && text.target.value.length <= 9) {
      setRoutingNumber(text.target.value.replace(/\s/g, ""));
    }
  };

  const handleAccountNumberChange = (text) => {
    if (!isNaN(text.target.value)) {
      setAccountNumber(text.target.value.replace(/\s/g, ""));
    }
  };

  const handleVerifyAccountNumberChange = (text) => {
    if (!isNaN(text.target.value)) {
      setVerifyAccountNumber(text.target.value.replace(/\s/g, ""));
    }
  };
  const toggle = () => setModalVisible(!modalVisible);

  const navigate = useNavigate();

  const [processingData, setProcessingData] = useState(false);

  const handleSubmit = async () => {
    setProcessingData(true);
    const allValid = await RoutingAccountValidate();

    const successCallback = (response) => {
      if (response?.status === "Error") {
        setShowBillingApiErrorAlert(true);
      } else if (edit === true) {
        setShowBillingApiErrorAlert(false);
        navigate("/onboarding", {
          state: { editMode: true, displayBankingToast: true },
        });
      } else {
        setShowBillingApiErrorAlert(false);
        navigate("/onboarding", {
          state: {
            editMode: false,
            displayBankingToast: true,
            bankingOnFile: true,
            taxFormOnFile: taxFormOnFile,
          },
        });
      }

      setProcessingData(false);
    };

    const errorCallback = (error) => {
      logTrace(error, "error when calling rewardsApi.updateBankingInfo", "handling submit in BankingDetails.jsx");

      setShowBillingApiErrorAlert(true);
      setProcessingData(false);
    };

    if (allValid) {
      rewardsApi.updateBankingInfo(
        {
          data: {
            AccountNumber: AccountNumber,
            RoutingNumber: RoutingNumber,
          },
        },
        successCallback,
        errorCallback
      );
    }
  };
  const handleFlag = () => {
    const successCallback = (response) => {
      setCancelFlag(response);
    };

    const errorCallback = (error) => {
      logTrace(error, "error when calling rewardsApi.getCancelFlag", "handling flag in BankingDetails.jsx");
      setCancelFlag(false);
    };
    rewardsApi.getCancelFlag(successCallback, errorCallback);
  };
  const handleCancel = () => {
    toggle();
  };

  const validateForm = useRef();

  useEffect(() => {
    handleFlag();

    validateForm.current = new GdkValidateForm({
      content: "#number-validate",
    });

    document.getElementById("RoutingNumber").onchange = () => {
      removeError(document.getElementById("RoutingNumber-form"));
      setProcessingData(false);
    };

    document.getElementById("AccountNumber").onchange = () => {
      removeError(document.getElementById("AccountNumber-form"));
      setProcessingData(false);
    };

    document.getElementById("VerifyAccountNumber").onchange = () => {
      removeError(document.getElementById("VerifyAccountNumber-form"));
      setProcessingData(false);
    };
  }, []);

  return (
    <>
      <div>
        {cancelFlag && modalVisible && (
          <ConfirmationModal setShow={setModalVisible} />
        )}
        <div className="container">
          <h4 className="header-Banking">Enter Checking Details:</h4>
          {showBillingHeader && <br />}
          <form id="number-validate" noValidate="">
            <div className={showBillingHeader ? "panel-Banking" : ""}>
              <div
                id="RoutingNumber-form"
                className="form-field large-text-input"
              >
                <label htmlFor="RoutingNumber">Routing Number: </label>
                <input
                  className="input-details"
                  data-testid="routingTest"
                  aria-invalid="true"
                  id="RoutingNumber"
                  type="text"
                  name="RoutingNumber"
                  value={RoutingNumber}
                  onChange={handleRoutingNumberChange}
                />
              </div>
              <div
                id="AccountNumber-form"
                className="form-field large-text-input"
              >
                <div className="row tooltip-label-container">
                  <div className="col-md-12 control-group">
                    <label htmlFor="AccountNumber">Account Number:</label>
                    <ToolTip
                    idName="tooltip-AccountNumber"
                    triggerId="tooltip-trigger-AccountNumber"
                    body="GEICO Rewards pays users through their checking account. Please provide correct account information."
                  ></ToolTip>
                  </div> 
                </div>
                <input
                  className="input-details"
                  data-testid="accountTest"
                  aria-invalid="true"
                  id="AccountNumber"
                  type="text"
                  name="AccountNumber"
                  value={AccountNumber}
                  onChange={handleAccountNumberChange}
                />
              </div>
              <div
                id="VerifyAccountNumber-form"
                className="form-field large-text-input"
              >
                <label htmlFor="VerifyAccountNumber">
                  Verify Account Number:{" "}
                </label>
                <input
                  className="input-details"
                  data-testid="verifyTest"
                  aria-invalid="true"
                  id="VerifyAccountNumber"
                  type="text"
                  name="VerifyAccountNumber"
                  value={VerifyAccountNumber}
                  onChange={handleVerifyAccountNumberChange}
                />
              </div>
            </div>
            {showBillingHeader && (
              <div className="panel-Banking banking-Image">
                <div id="checkImage">
                  <img src={checkNumber} alt=""></img>
                </div>
              </div>
            )}
            <div>
              <div>
                {cancelFlag && edit && (
                  <button
                    type="button"
                    data-testid="cancel-button"
                    className={
                      "btn btn--secondary " +
                      (showBillingHeader
                        ? "button-Banking btn--pull-right"
                        : "")
                    }
                    onClick={handleCancel}
                    disabled={processingData}
                  >
                    <span>Cancel</span>
                  </button>
                )}
                <button
                  type="button"
                  data-testid="submit-button"
                  className={
                    "btn btn--primary " +
                    (showBillingHeader ? "button-Banking btn--pull-right" : "")
                  }
                  onClick={handleSubmit}
                  disabled={processingData}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <QuantumMetrics />
    </>
  );
};

export default BankingDetails;
