import { useState } from "react";
import "../../styles/UpcomingPromotions.css";
import "@gdk/card-selections/dist/styles/gdk-card-selections.css";
import UniversalModal from "../UniversalModal";
import * as promotionTypes from "../../utils/constants/promotionTypes.js";

const PendingPromotionsCard = ({
  id,
  cardTitle,
  promotionDescription,
  daysRemaining,
  index,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const getClassName = (index) => {
    switch (index) {
      case 0:
        return "upcoming-campaign-cards-figma";
      default:
        return "upcoming-campaign-cards-figma offset-lg-1";
    }
  };

  const toggle = () => setModalVisible(!modalVisible);

  return (
    <>
      <div>
        {modalVisible && (
          <UniversalModal
            setShow={setModalVisible}
            header={cardTitle}
            contentType={promotionTypes.PendingPromotion}
            promotionDescription={promotionDescription}
            daysRemaining={daysRemaining}
          />
        )}
        <div className={getClassName(index) + " radio-cards-container"} id={id}>
          <h4 className="upcoming-campaign-cards-head card-overflow">
            {cardTitle}
          </h4>
          {daysRemaining != null && (
            <span className="counter-text card-overflow">
              <strong>{daysRemaining.toString()} days until start</strong>
            </span>
          )}
          <div className="chips chips-success" style={{ marginBottom: "1rem" }}>
            Activated
          </div>
          <p className="card-overflow" style={{ marginBottom: 0 }}>
            {promotionDescription}
          </p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="card-overflow"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              toggle();
            }}
          >
            Promotion Information
          </a>
        </div>
      </div>
    </>
  );
};

export default PendingPromotionsCard;
