import { useState, useRef, useEffect, useContext } from "react";
import { GdkToasts } from "@gdk/toasts";
import { GdkLoader } from "@gdk/loader";
import {
  BANKING_ACCOUNT_NEEDS_UPDATING,
  DOCUSIGN_DECLINE,
  DOCUSIGN_EVENT,
  DOCUSIGN_SIGNING_COMPLETE
} from "../utils/constants/index";
import { useNavigate } from "react-router-dom";
import {
  UserContext,
  UserSummaryContext,
  UserIncentiveSummaryContext,
  UserPendingPromotionsContext
} from "../context/userContext";
import { FeatureFlagContext } from "../context/FeatureFlagContextProvider";
import ActivePromotions from "./CampaignCards/ActivePromotions";
import PendingPromotions from "./CampaignCards/PendingPromotions";
import UpcomingPromotions from "./CampaignCards/UpcomingPromotions";
import { getDaysRemaining } from "../helpers/incentiveHelper";
import ProgressBar from "./ProgressBar";
import gift from "../images/present.png";
import giftDisabled from "../images/presentDisabled.png";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";
import ActivePromotionModal from "./CampaignCards/ActivePromotionModal";
import UniversalModal from "./UniversalModal";
import * as promotionTypes from "../utils/constants/promotionTypes";
import PrizeWheel from "./PrizeWheel";
import "../styles/wheel.css";
import "../styles/Home.css";
import "../styles/gdk";
import QuantumMetrics from "./QuantumMetrics";

const Home = () => {
  const [agentRole, setAgentRole] = useState("");
  const [participationLevel, setParticipationLevel] = useState("");
  const gdkToast = useRef(null);
  const gdkHomeLoader = useRef(null);
  const [toastHeader, setToastHeader] = useState("");
  const [toastDescription, setToastDescription] = useState("");
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [agentFirstName, setAgentFirstName] = useState("");

  const userIncentiveSummary = useContext(UserIncentiveSummaryContext);
  const userSummary = useContext(UserSummaryContext);
  const featureFlags = useContext(FeatureFlagContext);
  const userPendingPromotions = useContext(UserPendingPromotionsContext);
  const [chosenIncentiveId, setChosenIncentiveId] = useState("");
  const [incentiveType, setIncentiveType] = useState("");
  const [incentiveName, setIncentiveName] = useState("");
  const [currentIncentive, setCurrentIncentive] = useState(null);
  const [numberOfSpins, setNumberOfSpins] = useState(0);
  const [daysLeftBeforeEndDate, setDaysLeftBeforeEndDate] = useState(0);
  const [redeemDaysLeft, setRedeemDaysLeft] = useState(0);
  const [nextPayout, setNextPayout] = useState();
  const [spifProgress, setSpifProgress] = useState();
  const [spifGoal, setSpifGoal] = useState();
  const [promotionType, setPromotionType] = useState();
  const [principalFilter, setPrincipalFilter] = useState("");
  const [isUserActive, setIsUserActive] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [pendingPromosFlag, setPendingPromosFlag] = useState(false);
  const [gameOnHomePageFlag, setGameOnHomePageFlag] = useState(false);
  const [figmaHeaderFlag, setFigmaHeaderFlag] = useState(false);
  const [hasPendingPromotions, setHasPendingPromotions] = useState(true);
  const [prizeWheelSoundFlag, setPrizeWheelSoundFlag] = useState(false);
  const [spinsByIncentive, setSpinsByIncentive] = useState({});
  const [totalEarnings, setTotalEarnings] = useState(0);

  const handleFeatureFlags = () => {
    setPendingPromosFlag(featureFlags?.PendingPromotionsFlag);
    setGameOnHomePageFlag(featureFlags?.PrizeWheelOnHomePageFlag);
    setFigmaHeaderFlag(featureFlags?.UpdatedHeaderOnHomePageFlag);
    setPrizeWheelSoundFlag(featureFlags?.PrizeWheelSoundFlag);
  };

  async function setUserFields() {
    if (user) {
      setAgentFirstName(toPascalCase(user.firstName));
      setAgentRole(user.role);
      setParticipationLevel(user.enrollmentStatus.participationLevel);
    }
  }

  async function setInitialChosenIncentive() {
    if (userIncentiveSummary && userIncentiveSummary.length > 0) {
      setChosenIncentiveId(userIncentiveSummary[0].incentiveId); // default incentive is first promotion card
    }
  }

  useEffect(() => {
    if (userSummary) {
      setTotalEarnings(userSummary.totalEarnings);
    }
  }, [userSummary]);

  const handleSelectViewChange = (event) => {
    const selectedView = event.target.value;
    const upcomingSection = document.getElementById("upcomingSection");
    setPrincipalFilter(selectedView);

    if (
      selectedView !== "Producer" &&
      agentRole.toUpperCase() === "IA PRINCIPAL"
    ) {
      upcomingSection.style.display = "block";
    } else {
      upcomingSection.style.display = "none";
    }
  };

  const toPascalCase = (str) =>
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join("");

  useEffect(() => {
    gdkToast.current = new GdkToasts({
      content: "#confirmation-toast",
      type: "update",
      autoShow: false,
      floatTime: 3000,
      closeBtn: true,
      baseTimer: true
    });

    if (!gdkHomeLoader.current) {
      gdkHomeLoader.current = new GdkLoader({
        content: "#home-loader",
        type: "section",
        autoShow: false
      });
    } else {
      if (userIncentiveSummary) {
        gdkHomeLoader.current.hide();
      } else {
        gdkHomeLoader.current.show();
      }
    }
    if (userIncentiveSummary) {
      setInitialChosenIncentive();
      handleFeatureFlags();
      setIsUserActive(userIncentiveSummary.length > 0);
    }

    if (document.getElementById("home-gecko")) {
      if (
        !(
          participationLevel.toUpperCase() === "BOTH" &&
          agentRole.toUpperCase() === "IA PRINCIPAL"
        )
      ) {
        if (
          !document
            .getElementById("home-gecko")
            .classList.contains("gecko-padding")
        ) {
          document.getElementById("home-gecko").classList.add("gecko-padding");
        }
      } else {
        if (
          document
            .getElementById("home-gecko")
            .classList.contains("gecko-padding")
        ) {
          document
            .getElementById("home-gecko")
            .classList.remove("gecko-padding");
        }
      }
    }
  }, [userIncentiveSummary]);

  useEffect(() => {
    if (userPendingPromotions) {
      setHasPendingPromotions(userPendingPromotions.length > 0);
    }
  }, [userPendingPromotions]);

  const getIncentiveType = (incentiveId) => {
    for (let i = 0; i < userIncentiveSummary.length; i++) {
      if (userIncentiveSummary[i].incentiveId === incentiveId) {
        return {
          incentiveType: userIncentiveSummary[i].incentiveType.toUpperCase(),
          incentive: userIncentiveSummary[i]
        };
      }
    }
  };

  const getGameIncentiveDetails = (incentive) => {
    setIncentiveType("GAMIFICATION");
    setIncentiveName(incentive.incentiveName);
    setNumberOfSpins(incentive.gamificationTokens.tokensAvailable);
    setDaysLeftBeforeEndDate(getDaysRemaining(incentive.endDate));
    setRedeemDaysLeft(getDaysRemaining(incentive.redemptionEndDate));
  };

  const getSpifIncentiveDetails = (incentive) => {
    setIncentiveType("SPIF");
    setIncentiveName(incentive.incentiveName);
    setNextPayout(incentive.spifProgress.nextPayoutAmount);
    setSpifProgress(incentive.spifProgress.progress);
    setSpifGoal(incentive.spifProgress.nextIterationGoal);
    setDaysLeftBeforeEndDate(getDaysRemaining(incentive.endDate));
    setRedeemDaysLeft(getDaysRemaining(incentive.redemptionEndDate));
    setPromotionType(incentive.promotionType);
  };

  const toggle = () => setModalVisible(!modalVisible);

  useEffect(() => {
    if (userIncentiveSummary && chosenIncentiveId) {
      const { incentiveType, incentive } = getIncentiveType(chosenIncentiveId);

      if (incentiveType === "GAMIFICATION") {
        getGameIncentiveDetails(incentive);
      } else if (incentiveType === "SPIF") {
        getSpifIncentiveDetails(incentive);
      }

      setCurrentIncentive(incentive);
    }
  }, [chosenIncentiveId]);

  const [payoutOptions, setPayoutOptions] = useState([
    { option: "$1" },
    { option: "$2" },
  ]);

  useEffect(() => {
    for (let i = 0; i < userIncentiveSummary?.length; i++) {
      if (userIncentiveSummary[i].incentiveId === chosenIncentiveId && userIncentiveSummary[i].incentiveType === "Gamification") {
        let wheelOptions = [];
        let payouts = userIncentiveSummary[i].gamificationPayoutOptions.map(
          (option) => option.payout
        );

        payouts.forEach((element) =>
          wheelOptions.push({ option: "$" + element.toLocaleString() })
        );

        setPayoutOptions(wheelOptions);
        break;
      }
    }
  }, [chosenIncentiveId, userIncentiveSummary]);

  const updateAgentTaxInfoOnFile = async (change) => {
    let apiCallSuccess = false;
    let postResult;

    const handleError = (error) => {
      logTrace(error, "error when calling rewardsApi.updateAgentTaxInfoOnFile", "updateAgentTaxInfoOnFile in Home.jsx");
    };
    const handleSuccess = (responseData) => {
      apiCallSuccess = true;
      postResult = responseData;
    };

    await rewardsApi.updateAgentTaxInfoOnFile(
      change,
      { data: change },
      handleSuccess,
      handleError
    );
    if (apiCallSuccess) {
      return postResult;
    }
  };

  const uploadTaxForm = async () => {
    const handleError = (error) => {
      logTrace(error, "error when calling rewardsApi.uploadTaxInfo", "uploadTaxForm in Home.jsx");
    };
    const handleSuccess = () => {};
    await rewardsApi.uploadTaxInfo(handleSuccess, handleError);
  };

  useEffect(() => {
    if (user) {
      setUserFields();
      const query = new URLSearchParams(window.location.search);
      const eventType = query.get(DOCUSIGN_EVENT);
      if (
        user?.enrollmentStatus?.bankingStatus === BANKING_ACCOUNT_NEEDS_UPDATING
      ) {
        navigate("/onboarding", { state: { editMode: true } });
      }
      if (eventType) {
        if (eventType === DOCUSIGN_SIGNING_COMPLETE) {
          setToastHeader("Success");
          setToastDescription("Your document has been signed successfully.");
          gdkToast.current.show();
          if (user && !user.enrollmentStatus.taxInfo) {
            updateAgentTaxInfoOnFile(true);
          }
          uploadTaxForm();
        } else if (eventType === DOCUSIGN_DECLINE) {
          if (user && !user.enrollmentStatus.taxInfo) {
            navigate("/Onboarding");
          }
        } else {
          navigate("/Error");
        }
        window.history.replaceState(
          {},
          document.title,
          window.location.origin + window.location.pathname
        );
      }
    }
  }, [user]);

  const getPayoutToken = async (chosenIncentiveId) => {
    let apiCallSuccess = false;
    let payoutResult;

    const handleError = (error) => {
      logTrace(
        error,
        "error when calling rewardsApi.getPayoutToken",
        "getPayoutToken in PrizeWheel.jsx"
      );
    };

    const handleSuccess = (responseData) => {
      apiCallSuccess = true;
      if (responseData.status === "Failure" || responseData.status === 500) {
        window.location.href = "/error";
      } else {
        payoutResult = responseData.response.payoutAmount;
      }
    };

    await rewardsApi.getPayoutToken(
      chosenIncentiveId,
      handleSuccess,
      handleError
    );
    if (apiCallSuccess) {
      return payoutResult;
    }
  };

  useEffect(() => {
    if (gameOnHomePageFlag && userIncentiveSummary) {
      const spins = userIncentiveSummary.reduce((acc, incentive) => {
        if (incentive.gamificationTokens) {
          acc[incentive.incentiveId] =
            incentive.gamificationTokens.tokensAvailable;
        }
        return acc;
      }, {});
      setSpinsByIncentive(spins);
    }
  }, [userIncentiveSummary, gameOnHomePageFlag]);

  useEffect(() => {
    if (gameOnHomePageFlag && chosenIncentiveId) {
      setNumberOfSpins(spinsByIncentive[chosenIncentiveId] || 0);
    }
  }, [chosenIncentiveId, spinsByIncentive, gameOnHomePageFlag]);

  const updateSpinsForIncentive = (incentiveId, newSpins) => {
    setSpinsByIncentive((prevSpins) => ({
      ...prevSpins,
      [incentiveId]: newSpins
    }));
  };

  const updateTotalEarnings = (prizeAmount) => {
    setTotalEarnings((prevEarnings) => prevEarnings + prizeAmount);
  };

  return (
    <>
      <div id="home-loader" className="loader-container" data-testid="loader">
        <div className="loader-content">
          <div className="loader loader--animated" aria-label="Please wait">
            <div className="animated-loader-copy">
              Loading Your Rewards Page
              <div className="ellipses">
                <span className="ellipses-1">.</span>
                <span className="ellipses-2">.</span>
                <span className="ellipses-3">.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalVisible && !figmaHeaderFlag && (
        <ActivePromotionModal
          setShow={setModalVisible}
          incentive={currentIncentive}
        />
      )}
      {modalVisible && figmaHeaderFlag && (
        <UniversalModal
          setShow={setModalVisible}
          header={currentIncentive.incentiveName}
          contentType={promotionTypes.ActivePromotion}
          incentive={currentIncentive}
        />
      )}
      <div id="confirmation-toast" className="toasts-container">
        <div className="toasts toasts--confirmation">
          <div>
            <span className="icon-confirmation"></span>
          </div>
          <div role="status">
            <div className="toasts--heading">{toastHeader}</div>
            <div className="toasts--content">{toastDescription}</div>
          </div>
          <button className="toasts--close-btn icon-close">
            <div id="toasts--base-timer"></div>
          </button>
        </div>
      </div>

      {figmaHeaderFlag && (
        <div className="figma-header">
          <h2 className="header-main">
            Welcome to your GEICO Sales Rewards, {agentFirstName}.
          </h2>
        </div>
      )}

      <div className="container-fluid main">
        <form>
          {!(
            participationLevel.toUpperCase() === "BOTH" &&
            agentRole.toUpperCase() === "IA PRINCIPAL"
          ) &&
            !figmaHeaderFlag && (
              <div>
                <h2 className="header-main">
                  Welcome to your GEICO Rewards, {agentFirstName}!
                </h2>
                <div className="total-earned-container">
                  <div>
                    {userSummary && (
                      <h4>
                        You've earned{" "}
                        <span className="money-green">
                          ${userSummary.totalEarnings?.toLocaleString()}
                        </span>{" "}
                        so far. Nice work!
                      </h4>
                    )}
                    <h5>
                      <a href="/earnings">Earnings History</a>
                    </h5>
                  </div>
                </div>
              </div>
            )}
          {participationLevel.toUpperCase() === "BOTH" &&
            agentRole.toUpperCase() === "IA PRINCIPAL" &&
            !figmaHeaderFlag && (
              <div className="row">
                <div className="col-sm-4">
                  <div className="principal-filter">
                    <label htmlFor="principal-select" className="text">
                      Filter Promotions by:
                    </label>
                    <div className="select-box principal-box">
                      <select
                        id="principal-select"
                        name="principal-select"
                        onChange={handleSelectViewChange}
                      >
                        <option value="">None</option>
                        <option value="Agency">Agency</option>
                        <option value="Producer">Producer</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <h2 className="header-main">
                    Welcome to your GEICO Rewards, {agentFirstName}!
                  </h2>
                  <div className="total-earned-container">
                    <div>
                      {userSummary && (
                        <h4>
                          You've earned{" "}
                          <span className="money-green">
                            ${userSummary.totalEarnings.toLocaleString()}
                          </span>{" "}
                          so far. Nice work!
                        </h4>
                      )}
                      <h5>
                        <a href="/earnings">Earnings History</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="row">
            <div className="col-sm-4">
              {figmaHeaderFlag &&
                participationLevel.toUpperCase() === "BOTH" &&
                agentRole.toUpperCase() === "IA PRINCIPAL" && (
                  <div className="principal-filter-figma">
                    <label htmlFor="principal-select" className="text">
                      Filter Promotions by:
                    </label>
                    <div className="select-box principal-box">
                      <select
                        id="principal-select"
                        name="principal-select"
                        onChange={handleSelectViewChange}
                      >
                        <option value="">None</option>
                        <option value="Agency">Agency</option>
                        <option value="Producer">Producer</option>
                      </select>
                    </div>
                  </div>
                )}
              {userIncentiveSummary && (
                <>
                  {isUserActive && (
                    <ActivePromotions
                      updateChosenIncentiveId={setChosenIncentiveId}
                      principalFilter={principalFilter}
                      chosenIncentiveId={chosenIncentiveId}
                    />
                  )}
                  {pendingPromosFlag && hasPendingPromotions && (
                    <PendingPromotions />
                  )}
                </>
              )}
              {userIncentiveSummary &&
                !isUserActive &&
                !hasPendingPromotions && (
                  <div>
                    <img
                      id="home-gecko"
                      src="/images/geckoPose.png"
                      alt="Gecko"
                    />
                  </div>
                )}
            </div>
            <div className="col-sm-8">
              {/* Figma Total Earned Container*/}

              {figmaHeaderFlag && (
                <div className="flexible-container">
                  {userSummary && (
                    <h4>
                      You've earned{" "}
                      <span className="money-green">
                        ${totalEarnings.toLocaleString()}
                      </span>{" "}
                      so far. Nice work!
                    </h4>
                  )}
                  <div className="total-earned-button">
                    <button
                      type="button"
                      className="btn btn--secondary btn--full-mobile"
                      onClick={() => navigate("/earnings")}
                    >
                      <span>Check Out My Earnings</span>
                    </button>
                  </div>
                </div>
              )}

              {userIncentiveSummary &&
                isUserActive &&
                incentiveType === "GAMIFICATION" && (
                  <div className="game-section">
                    {!gameOnHomePageFlag ? (
                      <div>
                        <div>
                          <h4>
                            {incentiveName}
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              id="alternative-trigger-1"
                              className="tooltip-trigger"
                              aria-labelledby="tooltip-label"
                              onClick={(event) => {
                                event.preventDefault();
                                toggle();
                              }}
                            >
                              <span className="icon-info-i"></span>
                            </a>
                          </h4>
                          {daysLeftBeforeEndDate > 0 && (
                            <span className="days-left-counter floated-r">
                              {daysLeftBeforeEndDate} days left
                            </span>
                          )}
                          {daysLeftBeforeEndDate <= 0 && (
                            <span className="redemption-days-left-counter floated-r">
                              {redeemDaysLeft} days to redeem
                            </span>
                          )}
                        </div>
                        {daysLeftBeforeEndDate > 0 && (
                          <p>Spin the wheel of rewards!</p>
                        )}
                        {daysLeftBeforeEndDate <= 0 && (
                          <p>Promotion has ended</p>
                        )}
                        {numberOfSpins > 0 ? (
                          <>
                            <div className="game-indicator">
                              <img
                                src={gift}
                                alt="gift-enabled"
                                className="gift-image"
                                role="link"
                                onClick={() =>
                                  navigate("/game", {
                                    state: {
                                      chosenIncentiveId: chosenIncentiveId
                                    }
                                  })
                                }
                              />
                            </div>
                            <div className="game-number-spins-text">
                              {daysLeftBeforeEndDate >= 0 && (
                                <h4>
                                  You have{" "}
                                  <span className="money-green">
                                    {numberOfSpins.toLocaleString()}
                                  </span>{" "}
                                  spins available!
                                </h4>
                              )}
                              {daysLeftBeforeEndDate < 0 && (
                                <h4>
                                  You have{" "}
                                  <span className="money-green">
                                    {numberOfSpins.toLocaleString()}
                                  </span>{" "}
                                  spins left to redeem.
                                </h4>
                              )}
                            </div>
                          </>
                        ) : (
                          <div>
                            <div className="game-indicator">
                              <img src={giftDisabled} alt="giftDisabled" />
                            </div>
                            <div className="game-number-spins-text">
                              <h4>No Spins Available</h4>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <PrizeWheel
                        chosenIncentiveId={chosenIncentiveId}
                        incentiveName={incentiveName}
                        daysLeftBeforeEndDate={daysLeftBeforeEndDate}
                        redeemDaysLeft={redeemDaysLeft}
                        numberOfSpins={numberOfSpins}
                        payoutOptions={payoutOptions}
                        prizeWheelSoundFlag={prizeWheelSoundFlag}
                        getPayoutToken={getPayoutToken}
                        updateSpinsForIncentive={updateSpinsForIncentive}
                        updateTotalEarnings={updateTotalEarnings}
                        toggle={toggle}
                      />
                    )}
                  </div>
                )}
              {userIncentiveSummary &&
                isUserActive &&
                incentiveType === "SPIF" && (
                  <div>
                    {!gameOnHomePageFlag ? (
                      <h4>
                        <>
                          {incentiveName}
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            id="alternative-trigger-1"
                            className="tooltip-trigger"
                            aria-labelledby="tooltip-label"
                            onClick={(event) => {
                              event.preventDefault();
                              toggle();
                            }}
                          >
                            <span className="icon-info-i"></span>
                          </a>
                        </>
                        {daysLeftBeforeEndDate >= 0 && (
                          <span className="days-left-counter floated-r">
                            {daysLeftBeforeEndDate} days left
                          </span>
                        )}
                        {daysLeftBeforeEndDate < 0 && (
                          <span className="days-left-counter floated-r">
                            Promotion has ended
                          </span>
                        )}
                      </h4>
                    ) : (
                      <>
                        <h4 className="game-header">{incentiveName}</h4>
                        <div>
                          <p className="description-text">
                            {promotionType === "Quotes" ? "Quote " : "Sell "} 
                            policies to earn prizes. Read more information about this promotion{" "}
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              href="#"
                              onClick={(event) => {
                                event.preventDefault();
                                toggle();
                              }}
                            >
                              here
                            </a>
                            .
                          </p>
                        </div>
                        <div>
                          {daysLeftBeforeEndDate >= 0 && (
                            <span className="days-left-counter-bold">
                              {daysLeftBeforeEndDate} days left
                            </span>
                          )}
                          {daysLeftBeforeEndDate < 0 && (
                            <span className="days-left-counter">
                              Promotion has ended
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    <ProgressBar
                      goal={spifGoal}
                      progress={spifProgress}
                      payout={nextPayout.toLocaleString()}
                      promotionType={promotionType}
                    />
                  </div>
                )}
              {userIncentiveSummary && !isUserActive && (
                <div className="alert notice-bar">
                  <div className="alert--low-importance">
                    <span className="icon-info"></span>
                    <ul className="alert-list">
                      <h4 className="notice-header">
                        There are no current active promotions
                      </h4>
                    </ul>
                  </div>
                </div>
              )}
              {agentRole.toUpperCase() === "IA PRINCIPAL" && (
                <UpcomingPromotions />
              )}
            </div>
          </div>
        </form>
      </div>
      <QuantumMetrics />
    </>
  );
};

export default Home;
