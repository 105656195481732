const ConfirmationModalContent = () => {
  return (
    <>
      <h4 data-testid="confirmationTest">
        Your information will remain unchanged
      </h4>
    </>
  );
};

export default ConfirmationModalContent;
