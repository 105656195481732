import React, { useState, useEffect } from "react";
import { UserSummaryContext } from "./userContext";
import { useNavigate } from "react-router-dom";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError, logTrace } from "../services/ApiServices/LoggingService";

const UserSummaryContextProvider = ({ children }) => {
    const [userSummary, setUserSummary] = useState(null);
    const navigate = useNavigate();

    async  function getUserSummary() {
      const handleError = (error) => {
        logTrace(error, "error when calling rewardsApi.getUserSummary", "getUserSummary in UserSummaryContextProvider.jsx");
      };

      const handleSuccess = (responseData) => {
        setUserSummary(responseData.response);
      }
      await rewardsApi.getUserSummary(handleSuccess, handleError);
    }

    useEffect(() => {
      getUserSummary();
    }, [navigate]);
    return <UserSummaryContext.Provider value={userSummary}>{children}</UserSummaryContext.Provider>;
  };
  
  export default UserSummaryContextProvider;